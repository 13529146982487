<template>
  <div class="mb-12">
    <SubHeader :type="$t('contacts.contactsAdmin')" />
    <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col">
      <div class="grid md:grid-cols-5 h-auto pb-10 gap-4">
        <div class="md:col-span-1">
          <SidebarProject />
        </div>
        <TransitionFade>
          <div class="md:col-span-4 h-auto space-y-3">
            <Card>
              <template #content>
                <div class="flex flex-col w-full">
                  <div class="grid grid-cols-4 gap-4">
                    <div></div>
                    <div></div>
                    <div></div>
                    <Button type="button" @click="openModalNewContact"
                            class="right p-button-success p-button-icon-right" icon="pi pi-plus p-button-icon-right"
                            label="Nuevo contacto"
                            raised />
                  </div>
                </div>
                <div class="flex flex-col w-full">
                  <div class="text-gray-700 block text-sm mb-3 font-semibold">{{ $t("contacts.contactsAdmin") }}</div>
                  <div class="grid grid-cols-1">
                    <div class="my-0">
                      <TableSkeleton class="min-w-full" v-if="!contacts.length" />
                      <table class="min-w-full rounded" v-if="contacts.length">
                        <thead class="bg-gray-50">
                        <tr>
                          <th scope="col"
                              class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">
                            <div class="flex gap-2 cursor-pointer">
                              <span>{{ $t("contacts.name") }}</span>
                            </div>
                          </th>
                          <th scope="col"
                              class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">
                            <div class="flex gap-2 cursor-pointer">
                              <span>{{ $t("contacts.surname") }}</span>
                            </div>
                          </th>
                          <th scope="col"
                              class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">
                            <div class="flex gap-2 cursor-pointer">
                              <span>{{ $t("contacts.email") }}</span>
                            </div>
                          </th>
                          <th scope="col"
                              class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">
                            <div class="flex gap-2 cursor-pointer">
                              <span>{{ $t("contacts.project") }}</span>
                            </div>
                          </th>
                          <th scope="col"
                              class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">
                            <div class="flex gap-2 cursor-pointer">
                              <span></span>
                            </div>
                          </th>
                        </tr>
                        </thead>

                        <tbody class="bg-white divide-y divide-gray-100">
                        <tr v-for="(contact, index) in contacts" :key="index">
                          <td class="pl-3 py-3">
                            <div class="flex flex-col justify-start items-start text-sm">
                              <p class="font-medium">{{ contact.name }}</p>
                            </div>
                          </td>
                          <td class="pl-3 py-3 text-gray-500">
                            <div class="flex flex-col justify-start items-start text-sm">
                              <p class="font-medium">{{ contact.surname }}</p>
                            </div>
                          </td>

                          <td class="pl-3 py-3 text-gray-500">
                            <div class="flex flex-col justify-start items-start text-sm">
                              <p class="font-medium">{{ contact.email }}</p>
                            </div>
                          </td>

                          <td class="pl-3 py-3 text-gray-500">
                            <div class="flex flex-col justify-start items-start text-sm">
                              <p class="font-medium">{{ contact.project.company }}</p>
                            </div>
                          </td>

                          <td>
                            <VerticalDotsDropdown>
                              <template v-slot:buttons>
                                <a
                                  @click.stop="openModalEditContact(contact, index)" href="javascript:void(0);"
                                  class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-600 group"
                                  role="menuitem">
                                  <DropdownOptionEdit />
                                  Editar</a>
                                <a
                                  @click.stop="deleteContact(contact)" href="javascript:void(0);"
                                  class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-red-100 hover:text-red-600 group"
                                  role="menuitem">
                                  <DropdownOptionDelete />
                                  Eliminar</a>
                              </template>
                            </VerticalDotsDropdown>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </TransitionFade>
      </div>
    </div>
    <Dialog header="Nuevo contacto" v-model:visible="showModalNewContact" position="center" modal :dismissableMask="true"
            :style="{ width: '60rem', padding: 0 }">
      <div class="flex flex-col">
        <form @submit.prevent="saveContact">
          <div class="form-group">
            <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("contacts.name") }}</label>
            <InputText v-model="contact.name" type="text" name="name" class="p-inputtext-sm" />
          </div>
          <div class="form-group">
            <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("contacts.surname") }}</label>
            <InputText v-model="contact.surname" type="text" name="surname" class="p-inputtext-sm" />
          </div>
          <div class="form-group">
            <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("contacts.email") }}</label>
            <InputText v-model="contact.email" type="email" name="email" class="p-inputtext-sm" />
          </div>
          <div class="form-group">
            <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("contacts.role") }}</label>
            <div class="card flex flex-wrap justify-content-center grid grid-cols-2 gap-2 m-2">
              <div class="flex align-items-center grid grid-cols-1 gap-1" v-for="role in roleContacts" :key="role.id">
                <div>
                  <Checkbox v-model="contact.role_contacts" :inputId="'ingredient'+role.id" :value="role.id" />
                  <label for="ingredient1" class="ml-2"> {{ role.name }}</label>
                </div>
                <p class="w-full ml-5 color-grey-200">{{ role.description }}</p>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-end space-x-3 pt-3">
            <Button  type="button" label="Cancelar" class="p-button-light shadow" />
            <Button type="submit" label="Guardar"  class="p-button-primary shadow" />
          </div>
        </form>
      </div>
    </Dialog>


    <Dialog header="Editar contacto" v-model:visible="showModalEditContact" position="center" modal :dismissableMask="true"
            :style="{ width: '60rem', padding: 0 }">
      <div class="flex flex-col">
        <form @submit.prevent="updateContact">
          <div class="form-group">
            <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("contacts.name") }}</label>
            <InputText v-model="contact.name" type="text" name="name" class="p-inputtext-sm" />
          </div>
          <div class="form-group">
            <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("contacts.surname") }}</label>
            <InputText v-model="contact.surname" type="text" name="surname" class="p-inputtext-sm" />
          </div>
          <div class="form-group">
            <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("contacts.email") }}</label>
            <InputText v-model="contact.email" type="email" name="email" class="p-inputtext-sm" />
          </div>
          <div class="form-group">
            <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("contacts.role") }}</label>
            <div class="card flex flex-wrap justify-content-center grid grid-cols-2 gap-2 m-2">
              <div class="flex align-items-center grid grid-cols-1 gap-1" v-for="role in roleContacts" :key="role.id">
                <div>
                  <Checkbox v-model="contact.role_contacts" :inputId="'ingredient'+role.id" :value="role.id" />
                  <label for="ingredient1" class="ml-2"> {{ role.name }}</label>
                </div>
                <p class="w-full ml-5 color-grey-200">{{ role.description }}</p>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-end space-x-3 pt-3">
            <Button type="button" label="Cancelar" class="p-button-light shadow" />
            <Button type="submit" label="Actualizar" class="p-button-primary shadow" />
          </div>
        </form>
      </div>
    </Dialog>
  </div>
</template>

<script>
// App components
import SubHeader from "@/components/SubHeader"
import axiosClient from "@/config/AxiosClient"
import TransitionFade from "@/components/TransitionFade"
import SidebarProject from "@/components/SidebarProject"
import TableSkeleton from "@/components/UI/TableSkeleton"

// Framework components
import Card from "primevue/card"
import Button from "primevue/button"
import Dialog from "primevue/dialog"
import { mapGetters } from "vuex"
import VerticalDotsDropdown from "../../components/UI/buttons/VerticalDotsDropdown.vue"
import DropdownOptionDelete from "../../components/Dropdowns/DropdownOptionDelete.vue"
import DropdownOptionEdit from "../../components/Dropdowns/DropdownOptionEdit.vue"
import InputText from "primevue/inputtext"
import Checkbox from "primevue/checkbox"

export default {
  name: "ProjectContact",
  components: {
    DropdownOptionEdit,
    DropdownOptionDelete,
    VerticalDotsDropdown,
    // App components
    SubHeader,
    TransitionFade,
    SidebarProject,
    TableSkeleton,
    InputText,
    Checkbox,
    // Framework components
    Card,
    Button,
    Dialog
  },
  async created() {
    if (this.currentProject) this.getProject(this.currentProject)
    let response = await axiosClient.get(`/api/2.0/project/${this.currentProject}/roles`)
    this.roles = response.data.map(f => ({ label: f.replace(/client-/g, ""), value: f }))
  },
  mounted() {
    this.getContacts()
    this.getRoleContacts()
  },
  data: function() {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      contacts: [],
      showModalNewContact: false,
      showModalEditContact: false,
      contact: {
        name: "",
        surname: "",
        email: "",
        role_contacts: null
      },
      roleContacts: []

    }
  },
  computed: {
    ...mapGetters({ permissions: "Projects/permissionsForProject" }),
    validateInputMember() {
      return this.members.email === ""
    }
  },
  methods: {
    openModalNewContact() {
      this.showModalNewContact = true
    },
    openModalEditContact(contact) {
      this.contact = contact
      this.contact.role_contacts = contact.role_contact.map((x) => x.id)
      this.showModalEditContact = true
    },
    async getRoleContacts() {
      let response = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/role-contacts`)
      this.roleContacts = response.data.data
    },
    async saveContact() {
      console.log(this.contact)
      try {
        const res = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.currentProject}/contact`, this.contact)
        if (res.data.success) {
          this.$toast.success("Guardado correctamente")
          await this.getContacts()
          this.contact = {
            name: "",
            surname: "",
            email: "",
            role_contacts: null
          }
          this.showModalNewContact = false
        }
      } catch (e) {
        console.log(e)
        this.$toast.error("Por favor verifique los datos ingresados, todos los campos son requeridos.")
      }
    },
    async updateContact() {
      try {
        const res = await axiosClient.put(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.contact.id}/contact`, this.contact)
        if (res.data.success) {
          this.$toast.success("Actualizado correctamente")
          await this.getContacts()
          this.contact = {
            name: "",
            surname: "",
            email: "",
            role_contacts: null
          }
          this.showModalEditContact = false
        }
      } catch (e) {
        console.log(e)
        this.$toast.error("Por favor verifique los datos ingresados, todos los campos son requeridos.")
      }
    },
    deleteContact(contact) {
      this.$confirm.require({
        group: "confirm",
        message: "Estas seguro de eliminar este contacto?",//this.$t("You will run the sync now."),
        header: this.$t("Are you sure?"),
        icon: "pi pi-info-circle",
        acceptClass: "p-button-primary",
        acceptLabel: this.$t("yesContinue"),
        rejectLabel: this.$t("cancel"),
        accept: async () => {
          const res = await axiosClient.delete(`${process.env.VUE_APP_URL_API}/api/2.0/project/${contact.id}/contact`)
          if (res.data.success) {
            this.$toast.success("Actualizado correctamente")
            await this.getContacts()
            this.contact = {
              name: "",
              surname: "",
              email: "",
              role_contacts: null
            }
            this.$toast.success("Eliminado correctamente")
          }
        }
      })
    },
    getProject(id) {
      this.$store.dispatch("Projects/getProject", id)
    },
    async getContacts() {
      let response = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.currentProject}/contact`)
      if (response.status == 200) {
        this.contacts = response.data.data
      }
    }
  }
}
</script>
