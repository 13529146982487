<template>
  <div class="relative inline-block text-left" v-click-away="closeShowDropdown">
    <div>
      <div class="cursor-pointer" id="options-menu" @click.stop="openShowDropdown">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500 hover:text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
        </svg>
      </div>
    </div>
    <TransitionFade2>
      <div class="origin-top-right absolute z-75 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5" v-if="showDropdown">
        <div class="py-2 px-2" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <slot name="buttons"> </slot>
        </div>
      </div>
    </TransitionFade2>
  </div>
</template>

<script>
import TransitionFade2 from "@/components/TransitionFade2"
export default {
  name: `VerticalDotsDropdown`,
  components: {
    TransitionFade2
  },
  data: function () {
    return {
      showDropdown: false
    }
  },
  methods: {
    openShowDropdown: function () {
      this.showDropdown = !this.showDropdown
    },
    closeShowDropdown: function () {
      this.showDropdown = false
    }
  }
}
</script>
